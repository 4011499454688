import React from 'react'
import RichText from './RichText'
import styled from 'styled-components'

const EmbeddedVideo = ({
  video: {
    json: video
  }
}) => {
  return (
    <EmbeddedVideo_wrapper>
      <RichText
        json={video}
        isEmbeded />
    </EmbeddedVideo_wrapper>
  )
}

const EmbeddedVideo_wrapper = styled.div`
`

export default EmbeddedVideo
