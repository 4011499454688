import React from 'react'
import styled from 'styled-components'
import FlavText from '../FlavText'
import RichText from '../RichText'
import {
  sectionGrid,
  colors,
  VW,
  typography,
  font
} from '../../styles'

const Values = ({
  flav: {
    type: flavType,
    flavLeft: {
      json: flavLeft
    },
    flavRight: {
      json: flavRight
    }
  },
  intro,
  bulletPoints,
  text
}) => {
  return (
    <StyledValuesWrapper>
      <FlavText
        type={flavType}
        left={flavLeft}
        right={flavRight} />
      <div className="values-container">
        <div className="values-content">
          <MainText>
            <RichText json={text} />
          </MainText>
          {intro && (
            <Intro>
              <RichText json={intro.json} />
            </Intro>
          )}
          {bulletPoints && (
            <BulletPoints>
              {bulletPoints.json.content.map((item, i) => (
                <li key={`bulletPoints-${i}`}>
                  <RichText json={item} />
                </li>
              ))}
            </BulletPoints>
          )}
        </div>
      </div>
    </StyledValuesWrapper>
  )
}

const StyledValuesWrapper = styled.div`
  padding: 10px 0 65px;

  .values-container {
    ${sectionGrid}
  }

  .values-content {
    max-width: 600px;
    margin: 10px auto 0;
    padding: 0 20px;
    display: block;
  }

  @media (min-width: ${VW.desktop}) {
    .values-content {
      max-width: initial;
      padding: initial;
      display: grid;
      margin: initial;
      grid-template-columns: repeat(12,1fr);
    }
  }
`

const MainText = styled.div`
  ${typography.body.big}

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 5;
    grid-column-end: 11;
  }
`

const Intro = styled.div`
  font-family: ${font.typewriter};
  ${typography.body.tiny}
  margin: 15px 0;

  p {
    margin: 0;
  }

  @media (min-width: ${VW.desktop}) {
    margin: 15px 0 30px;
    grid-column-start: 5;
    grid-column-end: 11;
  }
`

const BulletPoints = styled.ul`
  ${typography.body.small}
  
  li {
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;

    & > * {
      display: table;
      margin: 0 0 0 23px;

      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${colors.red}
      }
    }

    a {
      text-decoration: underline;
    }
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 5;
    grid-column-end: 13;

    li {
      width: 50%;
    }
  }
`

export default Values