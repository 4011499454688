import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Hero from '../components/hero/Hero'
import PhotoStrip from '../components/PhotoStrip'
import Values from '../components/about/Values'
import Bio from '../components/about/Bio'
import Meet from '../components/about/Meet'
import EmbeddedVideo from '../components/EmbeddedVideo'

const About = ({
  data: {
    contentfulAboutPage: {
      seoTitle,
      seoDescription,
      seoImage,
      heroCarouselItems,
      imageTicker,
      valuesFlavourText,
      valuesBulletPointIntro,
      valuesBulletPoints,
      valuesText: {
        json: valuesText
      },
      bioBigText: {
        json: bioBigText
      },
      bioSmallText: {
        json: bioSmallText
      },
      bioSignatures,
      bioImage,
      meetFlavourText,
      meetIntro,
      meetText: {
        json: meetText
      },
      meetFeaturedPoints,
      embeddedVideoSection
    }
  }
}) => {
  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.fluid.src}
        slug='' />
      <Hero carouselItems={heroCarouselItems} />
      <Values
        flav={valuesFlavourText}
        intro={valuesBulletPointIntro}
        bulletPoints={valuesBulletPoints}
        text={valuesText} />
      <PhotoStrip images={imageTicker.map((i) => ({
        src: i.resize.src,
        width: i.resize.width,
        height: i.resize.height,
        alt: i.description || ''
      }))} />
      <Meet
        flav={meetFlavourText}
        text={meetText}
        intro={meetIntro}
        featuredPoints={meetFeaturedPoints} />
      <Bio
        signatures={bioSignatures}
        image={bioImage}
        bigText={bioBigText}
        smallText={bioSmallText} />
      {embeddedVideoSection && <EmbeddedVideo video={embeddedVideoSection} />}
    </>
  )
}

export const AboutQuery = graphql`
  query About($id: String!) {
    contentfulAboutPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
        }
      }
      heroCarouselItems {
        centerButton {
          title
          urlLink
        }
        centerText {
          json
        }
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
        bottomRightBigText
        topLeftBigText
      }
      imageTicker {
        description
        resize(height: 512) {
          src
          width
          height
        }
      }
      valuesFlavourText {
        type
        flavLeft {
          json
        }
        flavRight {
          json
        }
      }
      valuesBulletPointIntro {
        json
      }
      valuesBulletPoints {
        json
      }
      valuesText {
        json
      }
      bioBigText {
        json
      }
      bioImage {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        file {
          url
          contentType
        }
      }
      bioSmallText {
        json
      }
      meetFlavourText {
        type
        flavLeft {
          json
        }
        flavRight {
          json
        }
      }
      meetIntro
      meetText {
        json
      }
      meetFeaturedPoints {
        bulletPoints
        mainText {
          json
        }
        bottomImage {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        topImage {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      embeddedVideoSection {
        json
      }
    }
  }
`

export default About

// Temp Removed this
// bioSignatures {
//   image {
//     fluid(maxWidth: 1600, quality: 80) {
//       ...GatsbyContentfulFluid_withWebp_noBase64
//     }
//   }
//   name
// }