import React, { useContext } from 'react'
import styled from 'styled-components'
import HeroCarousel from '../shared/HeroCarousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { LayoutContext } from '../../contexts'
import { VARIABLE_CONSTANTS, VW } from '../../styles'

const Hero = ({ carouselItems }) => {
  const { headerBanner } = useContext(LayoutContext)

  return (
    <StyledHero hasBanner={headerBanner}>
      <HeroCarousel items={carouselItems} />
    </StyledHero>
  )
}

const StyledHero = styled.section`
  ${props => props.hasBanner && `
    padding-bottom: ${VARIABLE_CONSTANTS.mobileBanner}px;
    border-bottom: solid 2px black;

    @media (min-width: ${VW.desktop}) {
      padding-bottom: ${VARIABLE_CONSTANTS.desktopBanner}px;
    }
  `}
  height: ${VARIABLE_CONSTANTS.heroHeight};
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.05;
  }

  video {
    background-color: black;
  }
`

export default Hero
