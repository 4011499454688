import React from 'react'
import styled from 'styled-components'
import RichText from './RichText'
import {
  colors,
  sectionGrid,
  VW,
  typography
} from '../styles'

const FlavText = ({
  type,
  left,
  right,
  bottomSpacing
}) => {
  let fontSize
  let rightFontSize
  let mobileFontSize
  let mobileRightFontSize

  if(type === 'Large Font') {
    fontSize = typography.headlines.articleAlternate

    mobileFontSize = typography.headlines.regular

  } else if(type === 'Medium Font') {
    fontSize = typography.headlines.regular
    mobileFontSize = typography.headlines.regular
  
  } else if(type === 'Small Font') {
    fontSize = typography.headlines.regular
    rightFontSize = typography.body.regular
  
    
    mobileFontSize = fontSize
    mobileRightFontSize = rightFontSize
  }

  return (
    <StyledFlavText
      aFontSize={fontSize}
      rightFontSize={rightFontSize}
      mobileFontSize={mobileFontSize}
      mobileRightFontSize={mobileRightFontSize}
      bottomSpacing={bottomSpacing} >
      {/* <div> */}
        <RichText json={left} />
        <RichText json={right} />
      {/* </div> */}
    </StyledFlavText>
  )
}

const StyledFlavText = styled.div`
    max-width: 600px;
    padding: 30px 20px 0;
    margin: auto;

    p {
      ${props => props.mobileRightFontSize || props.mobileFontSize}
      margin: 0;
    }

    p:first-child {
      ${props => props.mobileFontSize}
      color: ${colors.red};
      ${props => props.bottomSpacing && 'margin-bottom: 20px;'}
    }

    @media (min-width: ${VW.desktop}) {
      ${sectionGrid}
      padding-top: 50px;

      p {
        ${props => props.rightFontSize || props.aFontSize}
        grid-column-start: 5;
        grid-column-end: 13;
        margin: ${props => props.rightFontSize && '12px;'}
      }

      p:first-child {
        ${props => props.aFontSize}
        grid-column-start: 1;
        grid-column-end: 4;
        margin: 0;
      }
    }
  `

export default FlavText