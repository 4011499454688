import React from 'react'
import styled from 'styled-components'
import Image from '../shared/Image'
import RichText from '../RichText'
import {
  sectionGrid,
  colors,
  VW,
  typography
} from '../../styles'
import Signatures from '../shared/Signatures'

const Bio = ({
  signatures,
  image,
  bigText,
  smallText
}) => {
  return (
    <StyledBio>
      <div className="bio-container">
        <div className="bio-content">
          {image.fluid ? (
            <BioImage
              backgroundColor='#000'
              fluid={image.fluid}
              alt={image.description} />
          ) : (
            <BioVideo>
              <video
                autoPlay
                muted
                loop
                playsInline >
                <source
                  src={image.file.url}
                  type='video/webm' />
              </video>
            </BioVideo>
          )}
          <BioContent>
            <BioBigText>
              <RichText json={bigText} />
            </BioBigText>
            <BioSmallText>
              <RichText json={smallText} />
            </BioSmallText>
            {signatures && (
              <Signatures
                signatures={signatures}
                color='red' />
            )}
          </BioContent>
        </div>
      </div>
    </StyledBio>
  )
}

const StyledBio = styled.div`
  padding: 40px 0;
  background-color: ${colors.lightGrey};
  
  .bio-container {
    ${sectionGrid}
    padding: 0 20px;
  }

  .bio-content {
    max-width: 600px;
    padding: 0 20px;
    display: block;
    margin: auto;
  }

  @media (min-width: ${VW.desktop}) {
    padding: 120px 0 0px;
    .bio-content {
      max-width: initial;
      padding: initial;
      display: grid;
      margin: initial;
      grid-template-columns: repeat(12,1fr);
    }
  }
`

const BioVideo = styled.div`
position: relative;
overflow: hidden;

  video {
    object-fit: cover;
    width: 100%;
    max-height: 80vh;
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 1;
    grid-column-end: 5;
    padding-bottom: 125%;
    transform: translateY(-50px);

    video {
      position: absolute;
      top: -90px;
      left: 0;
      bottom: 10px;
      right: 0;
      height: 100%;
      width: 72%;
      max-width: initial;
    }
  }
`

const BioImage = styled(Image)`
  grid-column-start: 1;
  grid-column-end: 5;

  &,
  * {
    overflow: initial !important;
  }

  .styled-image {
    height: initial;
  }

  img {
    width: 100% !important;
    object-fit: contain !important;
  }

  @media (min-width: ${VW.desktop}) {
    img {
      width: 73% !important;
      transform: translateY(-90px);
    }
  }
`

const BioContent = styled.div`
  grid-column-start: 5;
  grid-column-end: 13;
  margin-top: 40px;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
  }
`

const BioBigText = styled.div`
  ${typography.headlines.regular}
  
  p {
    margin: 0 0 40px;
  }
`

const BioSmallText = styled.div`
${typography.body.big}
  display: inline-block;
  max-width: 600px;
  margin-bottom: 40px;

  @media (min-width: ${VW.desktop}) {
    ${typography.body.small}
  }

  @media (max-width: 440px) {
    margin-bottom: 20px;
  }
`

export default Bio