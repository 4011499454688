import React from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import FlavText from '../FlavText'
import FeaturedPoints from './FeaturedPoints'
import {
  sectionGrid,
  colors,
  VW,
  typography
} from '../../styles'

const Meet = ( {
  flav: {
    type,
    flavLeft: {
      json: flavLeft
    },
    flavRight: {
      json: flavRight
    }
  },
  text,
  intro,
  featuredPoints
}) => {
  return (
    <StyledMeet>
      <FlavText
        type={type}
        left={flavLeft}
        right={flavRight} />
      <div className="meet-container">
        <div className="meet-content">
          <MainText>
            <RichText json={text} />
          </MainText>
          <Intro>
            <p>{intro}</p>
          </Intro>
          <FeaturedPoints
            featuredPoints={featuredPoints} />
        </div>
      </div>


    </StyledMeet>
  )
}

const StyledMeet = styled.div`
  padding: 30px 20px 50px;
  overflow: hidden;

  & > div {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0; 
  }

  .meet-container {
    ${sectionGrid}
  }

  .meet-content {
    max-width: 600px;
    display: block;
    margin: auto;

    & > * {
      grid-column-start: 1;
      grid-column-end: 13;
    }
  }

  @media (min-width: ${VW.desktop}) {
    padding: 30px 20px 0;
    overflow: initial;

    .meet-content {
      max-width: initial;
      display: grid;
      margin: initial;
      grid-template-columns: repeat(12,1fr);
    }
  }
`

const MainText = styled.div`
  ${typography.body.big}

  p {
    margin: 30px 0 0;
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 5 !important;
    grid-column-end: 11 !important;
  }
`

const Intro = styled.div`
  ${typography.body.big}
  color: ${colors.red};

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 5 !important;
    grid-column-end: 11 !important;
  }
`

export default Meet