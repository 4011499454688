import React, {
  useEffect,
  useRef,
  createRef,
  useState,
  useContext
} from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import {
  colors,
  VW,
  typography,
  VARIABLE_FUNCTION
} from '../../styles'
import { LayoutContext } from '../../contexts'

const heartSvg = (
  <svg viewBox="0 0 123 117" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M76.2843 62.7982C60.9455 47.7382 44.5667 30.0745 22.3477 26.5269C18.3412 25.8872 11.9624 25.0417 8.34092 27.3631C1.11283 31.9965 2.06922 42.4496 2.06922 49.889C2.06922 59.6635 4.36396 67.1977 12.6266 73.042C23.0563 80.4191 35.4236 84.7928 46.8596 90.2892C57.7851 95.5402 68.0622 101.892 78.793 107.484C85.3488 110.9 95.2597 116.433 103.044 115.271C109.113 114.366 111.297 105.157 112.869 100.794C116.094 91.8494 118.449 82.8386 119.35 73.3556C120.791 58.1808 121.799 41.5692 118.618 26.5269C117.357 20.5608 115.243 5.74717 108.793 3.008C103.118 0.59811 97.4914 5.12487 94.6291 9.54102C87.1572 21.069 83.2256 37.7365 81.9289 51.3524C81.5337 55.5021 80.4579 59.3602 78.1659 62.7982" stroke="#E32844" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const FeaturedPoints = ({ featuredPoints }) => {
  const contents_ref = useRef(featuredPoints.map(() => createRef()));
  const pointContentSection_ref = useRef();
  const { headerBanner } = useContext(LayoutContext)
  const [state, setState] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const refs = contents_ref.current
    const first = refs[0]
    const last = refs[refs.length - 1]

    if(typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
    }

    setState({
      first: first.current.clientHeight,
      last: last.current.clientHeight
    })

  }, [featuredPoints])

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const onScroll = () => {
    const wrapper = pointContentSection_ref && pointContentSection_ref.current
    if(!wrapper) { return }

    const elements = wrapper.children
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if(isInViewport(element)) {
        setActiveIndex(i)
        break
      } 
    }
  }

  const PointContentSection = ({ original }) => (
    <div
      className="point-content"
      ref={original && pointContentSection_ref} >
      {featuredPoints.map(({
        bulletPoints,
        mainText: {
          json: mainText
        },
        bottomImage: {
          fluid: {
            src: bottomImage
          }
        },
        topImage: {
          fluid: {
            src: topImage
          }
        }
      }, i) => (
        <PointWrapper
          key={`PointWrapper-${i}`}
          data-isviewport={i === activeIndex ? 'true' : 'false'} >
          <PointContent ref={contents_ref.current[i]}>
            {original && (
              <ImageBox_wrapper
                topImage={topImage}
                bottomImage={bottomImage} />
            )}
            <Intro>
              <RichText
                json={mainText} />
            </Intro>
            <ul>
              {bulletPoints.map((bullet) => (
                <li key={`point-${bullet}`}>
                  <p>{bullet}</p>
                </li>
              ))}
            </ul>
          </PointContent>
        </PointWrapper>
      ))}
    </div>
  )

  const ImageBox_wrapper = ({
    topImage,
    bottomImage
  }) => {
    return (
      <ImageBox
        topImage={topImage}
        bottomImage={bottomImage} >
        <div className="image-box">
          <div className="top-image" />
          <div className="middle-image">
            {heartSvg}
          </div>
          <div className="bottom-image" />
        </div>
      </ImageBox>
    )
  }

  return (
    <StyledFeaturePoints>
      <div className="point-container">
        <PointContentSection original />
        <div className="point-images">
          <PointContentSection />
          <ImageWrapper
            firstHeight={state.first}
            lastHeight={state.last} >
            <ImageContainer headerBanner={headerBanner}>
              {featuredPoints.map(({
                bottomImage: {
                  fluid: {
                    src: bottomImage
                  }
                },
                topImage: {
                  fluid: {
                    src: topImage
                  }
                }
              }, i) => (
                <ImageContent
                  key={`pointImage-${i}`}
                  i={i}
                  activeIndex={activeIndex}
                  data-isviewport={i === activeIndex ? 'true' : 'false'} >
                  <ImageBox_wrapper
                    topImage={topImage}
                    bottomImage={bottomImage} />
                </ImageContent>
              ))}
            </ImageContainer>
          </ImageWrapper>
        </div>
      </div>
    </StyledFeaturePoints>
  )
}

const StyledFeaturePoints = styled.div`
  max-width: 1200px;

  .point-content {
    padding-top: 30px;
  }

  .point-images {
    display: none;
    position: relative;

    .point-content {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (min-width:${VW.desktop}) {
    .point-images {
      margin-bottom: calc(67px + 500px);
    }

    .point-container {
      & > div {
        display: inline-block;
        width: 50%;
        vertical-align top;
      }

      & > .point-content {
        margin-bottom: 500px;
      }
    }    

    .point-content {
      padding-top: 330px;
    }
  }
`

const PointWrapper = styled.div`
  & + div {
    margin-top: 60px;
  }

  @media (min-width:${VW.desktop}) {
    width: 100%;

    & + div {
      margin-top: 360px;
    }
  }
`

const PointContent = styled.div`
  margin-left: auto;

  .image-box {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    right: -16px;
    bottom: 0;
  }

  ul {
    margin-top: 20px;
  }

  li {
    position: relative;

    p {
      ${typography.body.regular}
      margin: 0 0 0 23px;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: ${colors.red}
      }
    }
  }

  li + li {
    margin-top: 3px;
  }

  @media (min-width:${VW.desktop}) {
    padding-right: 40px;
    width: 420px;

    .image-box {
      display: none;
    }

    ul {
      margin-top: 30px;
    }
    
  }
`

const Intro = styled.div`
  ${typography.body.big}
  padding-top: 26%;

  p {
    margin: 0 !important;
  }

  @media (min-width:${VW.desktop}) {
    ${typography.headlines.regular}
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: calc(-${props => props.firstHeight}px / 2 + 430px);
  left: 0;
  bottom: calc(-${props => props.lastHeight}px - 40px);
  width:100%;

  @media (min-width:${VW.desktop}) {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  position: sticky;
  top: calc((100vh - ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader}) / 2);
  left: 0;
  width: 100%;
  padding-top: 0;
  transform: translateY(-50%);
  width:100%;

  &:after {
    content: '';
    padding-top: 66.89%;
    display: block;
  }
`

const ImageContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width:100%;
  opacity: ${props => props.i === props.activeIndex ? '1' : '0'};
  transition: opacity 0.3s ease;
`

const ImageBox = styled.div`
  position: relative;
  padding-bottom: calc((100% - 40px) * 0.6666666667);
  width: calc(100% - 40px);

  .top-image,
  .bottom-image {
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .top-image {
    height: 100%;
    width: 100%;
    background-image: url("${props => props.topImage}");
    background-position: right;
  }

  .middle-image {
    position: absolute;
    top: 69%;
    left: 76%;
    height: calc(100% * 0.2424892704);
    width: calc(100% * 0.171517341);
    z-index: 2;
  }

  .bottom-image {
    position: absolute;
    top: 84%;
    left: 72%;
    z-index: 1;
    max-width: 250px;
    height: calc(100% * 0.463362069);
    width: calc(100vw - 72%);
    background-size: cover;
    background-image: url("${props => props.bottomImage}");
  }

  @media (min-width:${VW.desktop}) {
    position: initial;
    padding: 0;
    margin: 0;
    width: 100%;

    .top-image {
      width:100%;

      &:after {
        content:'';
        padding-top:66.89%;
        display: block;
      }
    }

    .bottom-image {
      width: calc((100vw / 2) - 72%);
      max-width: 275px;
    }
  }
`


export default FeaturedPoints